import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Reference = makeShortcode("Reference");
const Footnote = makeShortcode("Footnote");
const Link = makeShortcode("Link");
const Alert = makeShortcode("Alert");
const Code = makeShortcode("Code");
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Provisioners are methods of using the CA to get certificates for humans or machines.
They offer different modes of authorization for the CA.
In this section we'll discuss the different provisioners, their target use cases, and how to add, remove, and configure them.`}</p>
    <h2>{`Overview`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#choosing-a-provisioner"
        }}>{`Choosing a Provisioner`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#authorization-scope-by-provisioner"
        }}>{`Authorization Scope by Provisioner`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#provisioner-management"
        }}>{`Provisioner Management`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#remote-provisioner-management"
        }}>{`Remote Provisioner Management`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#provisioner-types"
        }}>{`Provisioner Types`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#jwk"
            }}>{`JWK`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#oauthoidc-single-sign-on"
            }}>{`OAuth/OIDC Single Sign-on`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#x5c-x509-certificate"
            }}>{`X5C - X.509 Certificate`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#sshpop-ssh-certificate"
            }}>{`SSHPOP - SSH Certificate`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#acme"
            }}>{`ACME`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#nebula"
            }}>{`Nebula`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#scep"
            }}>{`SCEP`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#k8ssa-kubernetes-service-account"
            }}>{`K8sSA - Kubernetes Service Account`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#cloud-provisioners"
            }}>{`Cloud Provisioners`}</a></li>
        </ul>
      </li>
    </ul>
    <h2>{`Choosing a Provisioner`}</h2>
    <p>{`When choosing a provisioner for your use case,
you can narrow your options by starting from the types of
identifiers you need on a certificate. `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Identifier Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`JWK`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`OAuth`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`X5C`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`ACME `}<inlineCode parentName="th">{`dns-01`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`ACME `}<inlineCode parentName="th">{`http-01`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`ACME `}<inlineCode parentName="th">{`tls-alpn-01`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`ACME `}<inlineCode parentName="th">{`device-attest-01`}</inlineCode></th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Nebula`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`SCEP`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`K8sSA`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Cloud Provisioners`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hostname`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IP address`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hardware ID`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Service Account`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Human`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Authorization Scope by Provisioner`}</h2>
    <p>{`Every provisioner has a slightly different scope of authorization. Below is a
table detailing the authorization capabilities of each provisioner.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Provisioner Capabilities`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`x509-sign`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`x509-renew`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`x509-revoke`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`ssh-user-cert-sign`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`ssh-host-cert-sign`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`ssh-user-cert-renew`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`ssh-host-cert-renew`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`ssh-revoke`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`ssh-rekey`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JWK`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`OIDC`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️ `}<Reference id="f1" marker="1" mdxType="Reference" /></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`X5C`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nebula`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K8sSA`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ACME`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SCEP`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SSHPOP`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AWS`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Azure`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GCP`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✔️`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`𝗫`}</td>
        </tr>
      </tbody>
    </table>
    <br></br>
    <Footnote id="f1" marker="1" mdxType="Footnote"> Admin OIDC users can generate Host SSH Certificates.  Admins can be configured in the <Link href="#oauthoidc-single-sign-on" mdxType="Link">OIDC provisioner</Link>.</Footnote>
    <p>{`For an example of how to interpret this table, let's take the `}<inlineCode parentName="p">{`JWK`}</inlineCode>{` provisioner.
The `}<inlineCode parentName="p">{`JWK`}</inlineCode>{` provisioner is capable of signing, renewing, and revoking X.509
certificates, as well signing user and host SSH certificates. A `}<inlineCode parentName="p">{`JWK`}</inlineCode>{` provisioner
`}<em parentName="p">{`cannot`}</em>{` renew or rekey SSH certificates.`}</p>
    <p>{`An `}<inlineCode parentName="p">{`SSHPOP`}</inlineCode>{` provisioner `}<em parentName="p">{`can`}</em>{` revoke and rekey SSH certificates and renew SSH host
certificates. An `}<inlineCode parentName="p">{`SSHPOP`}</inlineCode>{` provisioner `}<em parentName="p">{`cannot`}</em>{` sign, renew, or revoke X.509
certificates, and it `}<em parentName="p">{`cannot`}</em>{` sign SSH user and host certificates or renew SSH
user certificates. `}</p>
    <p>{`It's important to understand the capabilities and limitations when selecting a
provisioner for a given workload.`}</p>
    <h2>{`Provisioner Management`}</h2>
    <p>{`Use the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/provisioner"
      }}><inlineCode parentName="a">{`step ca provisioner`}</inlineCode></a>{` command group to add, remove, or modify provisioner configurations. Run these commands directly on your CA machine. They need to modify the `}<inlineCode parentName="p">{`$(step path)/config/ca.json`}</inlineCode>{` configuration file.`}</p>
    <Alert severity="warning" mdxType="Alert">
  <div>
	<p><b>May I edit <Code mdxType="Code">ca.json</Code> directly?</b></p>
    <p>You may edit your <Code mdxType="Code">ca.json</Code> configuration file directly, but we strongly recommend using <a href="/docs/step-cli/reference/ca/provisioner"><Code mdxType="Code">step ca provisioner</Code></a> commands instead.
Fields in <Code mdxType="Code">ca.json</Code> may be encoded differently than you expect.</p>
	</div>
    </Alert>
    <p>{`Some provisioner options override global defaults for your CA.
For a list of global options, see the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/configuration"
      }}>{`configuration guide`}</a>{` section for the `}<inlineCode parentName="p">{`authority`}</inlineCode>{` configuration block.`}</p>
    <p>{`A remote provisioner management API can be enabled in `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.
It is disabled by default.
With remote provisioner management, the CA's provisioner configuration is stored in the database instead of `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`. (The global CA configuration remains in `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`.)`}</p>
    <p>{`This feature can be useful if you
have multiple CA administrators,
`}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/certificate-authority-server-production/#load-balancing-or-proxying-step-ca-traffic"
      }}>{`run several load-balanced`}</a>{` `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` instances,
or if you want to manage your provisioners remotely (eg. with Infrastructure as Code (IaC) tools; `}<a parentName="p" {...{
        "href": "#unattended-remote-provisioner-management"
      }}>{`see below`}</a>{`).`}</p>
    <p>{`See `}<a parentName="p" {...{
        "href": "#remote-provisioner-management"
      }}>{`Remote Provisioner Management`}</a>{` for more.`}</p>
    <h3>{`Common Provisioner Operations`}</h3>
    <p>{`Common provisioner operations include:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#add-a-provisioner"
        }}>{`Adding a provisioner`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#remove-a-provisioner"
        }}>{`Removing a provisioner`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#list-all-provisioners"
        }}>{`Listing all provisioner`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#modify-a-provisioner"
        }}>{`Modifying the configuration of an existing provisioner`}</a></li>
    </ul>
    <p>{`Unless you are using `}<a parentName="p" {...{
        "href": "#remote-provisioner-management"
      }}>{`remote provisioner management`}</a>{`, you must send a `}<inlineCode parentName="p">{`SIGHUP`}</inlineCode>{` signal, or restart the `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` process, for changes to your provisioner configuration to take effect.`}</p>
    <h4>{`Add a provisioner`}</h4>
    <p>{`Use `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/provisioner/add"
      }}><inlineCode parentName="a">{`step ca provisioner add`}</inlineCode></a>{` to add a provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`step ca provisioner add acme --type ACME
`}</code></pre>
    <p>{`See the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/provisioner/add"
      }}>{`command reference`}</a>{` for complete documentation and examples.`}</p>
    <h4>{`Remove a provisioner`}</h4>
    <p>{`Use `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/provisioner/remove"
      }}><inlineCode parentName="a">{`step ca provisioner remove`}</inlineCode></a>{` to remove provisioners:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`step ca provisioner remove acme
`}</code></pre>
    <p>{`You can also edit the `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{` configuration file and remove the entire block
containing the provisioner you'd like to remove.`}</p>
    <h4>{`List all provisioners`}</h4>
    <p>{`To get a list of all of your current provisioners, use `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/provisioner/list"
      }}><inlineCode parentName="a">{`step ca provisioner list`}</inlineCode></a>{` or the `}<inlineCode parentName="p">{`/provisioners`}</inlineCode>{` endpoint on your CA.`}</p>
    <h4>{`Modify a provisioner`}</h4>
    <p>{`Use `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/provisioner/update"
      }}><inlineCode parentName="a">{`step ca provisioner update`}</inlineCode></a>{` to update provisioner configurations:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`step ca provisioner update acme \\
   --x509-min-dur=20m \\
   --x509-max-dur=72h \\
   --x509-default-dur=36h
`}</code></pre>
    <p>{`In this example we've modified the minumum, maximum, and default durations for TLS certificates generated by this provisioner.`}</p>
    <p>{`Provisioner configuration can be used to affect X.509 and SSH certificate lifetimes, extensions, and templates.
There are some provisioner-specific options,
which are covered by the documentation for each provisioner type, below.`}</p>
    <h2>{`Remote Provisioner Management`}</h2>
    <p><strong parentName="p">{`This feature is disabled by default.`}</strong></p>
    <p>{`When remote provisioner management is enabled, your provisioner configuration is
stored in the database, rather than in `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`.
Provisioner configuration is managed by running `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/provisioner"
      }}><inlineCode parentName="a">{`step ca provisioner`}</inlineCode></a>{` commands locally or remotely.
These commands require you to sign in as an Admin user.`}</p>
    <h3>{`Enable Remote Provisioner Management`}</h3>
    <h4>{`On a New CA`}</h4>
    <p>{`To enable remote provisioner management on a new CA, pass `}<inlineCode parentName="p">{`--remote-management`}</inlineCode>{` to `}<inlineCode parentName="p">{`step ca init`}</inlineCode>{`. This will:`}</p>
    <ul>
      <li parentName="ul">{`Add an initial `}<a parentName="li" {...{
          "href": "#jwk"
        }}>{`JWK`}</a>{` provisioner called Admin JWK to the database.`}</li>
      <li parentName="ul">{`Create an initial Super Admin user, with default username `}<inlineCode parentName="li">{`step`}</inlineCode>{`, and link it to the Admin JWK provisioner.`}</li>
    </ul>
    <h4>{`On an Existing CA`}</h4>
    <p>{`To migrate your `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{` provisioners to the database, and to enable remote provisioner management on an existing CA:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Stop `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` if it is running.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Update your `}<inlineCode parentName="p">{`"authority"`}</inlineCode>{` block in `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{` to include the following:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`"authority": {
  "enableAdmin": true,
  "provisioners": [
    ...
  ]
},
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Start `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` from the command line.`}</p>
        <p parentName="li">{`When `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` starts up, it will:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Migrate the provisioners from your `}<inlineCode parentName="li">{`ca.json`}</inlineCode>{` to the database`}</li>
          <li parentName="ul">{`Repurpose the first JWK provisioner in your `}<inlineCode parentName="li">{`ca.json`}</inlineCode>{` as an administrative provisioner. If no JWK provisioner exists, it will add a `}<a parentName="li" {...{
              "href": "#jwk"
            }}>{`JWK`}</a>{` provisioner called Admin JWK to the database. You will be prompted for a password that will encrypt the new provisioner key.`}</li>
          <li parentName="ul">{`Create an initial Super Admin user, with username `}<inlineCode parentName="li">{`step`}</inlineCode>{`, and link it to the administrative provisioner.`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Remove old provisioner configurations from `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`.
Once your provisioners are migrated to the database, the provisioners in `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{` are ignored and you can remove them from that file:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`"authority": {
        "enableAdmin": true,
        "provisioners": []
},
`}</code></pre>
      </li>
    </ol>
    <h3>{`Managing Admin Users`}</h3>
    <p>{`With remote provisioner management,`}<br parentName="p"></br>{`
`}{`"Admins" can remotely manage provisioners.`}<br parentName="p"></br>{`
`}{`"Super Admins" are Admins that can also manage the list of Admins for the CA using `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/admin"
      }}><inlineCode parentName="a">{`step ca admin`}</inlineCode></a>{`.`}</p>
    <h4>{`Create An Admin User`}</h4>
    <p>{`To perform CA administrative operations, you sign into that provisioner as an Admin.`}</p>
    <p>{`As a Super Admin, lets create an Admin user linked to the Admin JWK provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`step ca admin add carl "Admin JWK"
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`SUBJECT    PROVISIONER        TYPE
carl       Admin JWK (JWK)    ADMIN
`}</code></pre>
    <h4>{`Authenticating as an admin`}</h4>
    <p>{`An Admin (or a Super Admin) is a combination of a username (a subject name) and a provisioner.
There are no passwords associated with administrators.
An administrator is just a subject name and a provisioner name.`}</p>
    <p><strong parentName="p">{`The default Super Admin username is `}<inlineCode parentName="strong">{`step`}</inlineCode>{`, and the default admin provisioner  is called `}<inlineCode parentName="strong">{`Admin JWK`}</inlineCode>{`.`}</strong></p>
    <p>{`You can authenticate as an administrator via the authentication scheme of the provisioner.
For example, for the default JWK provisioner, running administrative commands in `}<inlineCode parentName="p">{`step`}</inlineCode>{` will prompt for the provisioner password.`}</p>
    <p><strong parentName="p">{`Tip: Single Sign-On can be used for Admin users.`}</strong>{`
To enable SSO CA administration, first add an `}<a parentName="p" {...{
        "href": "#oauthoidc-single-sign-on"
      }}>{`OIDC provisioner`}</a>{` linked to your identity provider.
Then, add an Admin user associated with that provisioner, using your email address as the Admin's subject name.`}</p>
    <p>{`The Admin and Super Admin privileges `}<strong parentName="p">{`are not scoped to a provisioner`}</strong>{`:
Admins can modify any provisioner configurations.
Super Admins are Admins that can also modify Admins, regardless of provisioner.`}</p>
    <h3>{`Use Remote Provisioner Management`}</h3>
    <p>{`You're all set. 🎉`}</p>
    <p>{`You can use the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/provisioner"
      }}><inlineCode parentName="a">{`step ca provisioner`}</inlineCode></a>{` commands, from any client, to modify your CA's provisioner configuration.`}</p>
    <h4>{`Unattended Remote Provisioner Management`}</h4>
    <p>{`If you want to manage provisioners remotely using a script or an Infrastructure as Code (IaC) tool,
you will need an `}<em parentName="p">{`administrative certificate`}</em>{` and key.`}</p>
    <p>{`An administrative certificate must meet the following criteria:`}</p>
    <ul>
      <li parentName="ul">{`The Subject (or any SAN) must match the name of an Admin or Super Admin.`}</li>
      <li parentName="ul">{`The certificate must be issued by the provisioner of the Admin or Super Admin.`}</li>
      <li parentName="ul">{`The certificate must be valid and unexpired.`}</li>
      <li parentName="ul">{`A passively revoked certificate is valid until it expires; for immediate revocation, remove the admin instead of revoking the certificate.`}</li>
    </ul>
    <p>{`To create an admin certificate, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`step ca certificate step admin.crt admin.key --provisioner "Admin JWK"
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{}}>{`✔ Provisioner: Admin JWK (JWK) [kid: 5UasaZcgkI_PxQeZqssfq5mzFnGHOWL5g5kDt07Vc1k]
Please enter the password to decrypt the provisioner key:
✔ CA: https://localhost
✔ Certificate: admin.crt
✔ Private Key: admin.key
`}</code></pre>
    <p>{`With that certificate, you can use it as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`step ca provisioner add ... --admin-cert=admin.crt --admin-key=admin.key
`}</code></pre>
    <p>{`If the `}<inlineCode parentName="p">{`admin.crt`}</inlineCode>{` represents a Super Admin, the same flags can also be used to manage Admins:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`step ca admin add ... --admin-cert=admin.crt --admin-key=admin_key
`}</code></pre>
    <h2>{`Provisioner Types`}</h2>
    <h3>{`JWK`}</h3>
    <p>{`JWK is the default provisioner type. It uses public-key cryptography to sign and
validate a JSON Web Token (JWT).`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/cli"
      }}><inlineCode parentName="a">{`step`}</inlineCode></a>{` CLI tool will create a JWK provisioner when `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/init"
      }}><inlineCode parentName="a">{`step ca init`}</inlineCode></a>{` is used.`}</p>
    <h4>{`Example`}</h4>
    <p>{`Add a JWK provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add you@smallstep.com --create
`}</code></pre>
    <p>{`In the ca.json configuration file, a complete JWK provisioner example looks like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "type": "JWK",
    "name": "you@smallstep.com",
    "key": {
        "use": "sig",
        "kty": "EC",
        "kid": "NPM_9Gz_omTqchS6Xx9Yfvs-EuxkYo6VAk4sL7gyyM4",
        "crv": "P-256",
        "alg": "ES256",
        "x": "bBI5AkO9lwvDuWGfOr0F6ttXC-ZRzJo8kKn5wTzRJXI",
        "y": "rcfaqE-EEZgs34Q9SSH3f9Ua5a8dKopXNfEzDD8KRlU"
    },
    "encryptedKey": "eyJhbGciOiJQQkVTMi1IUzI1NitBMTI4S1ciLCJjdHkiOiJqd2sranNvbiIsImVuYyI6IkEyNTZHQ00iLCJwMmMiOjEwMDAwMCwicDJzIjoiTlV6MjlEb3hKMVdOaFI3dUNjaGdYZyJ9.YN7xhz6RAbz_9bcuXoymBOj8bOg23ETAdmSCRyHpxGekkV0q3STYYg.vo1oBnZsZjgRu5Ln.Xop8AvZ74h_im2jxeaq-hYYWnaK_eF7MGr4xcZGodMUxp-hGPqS85oWkyprkQLYt1-jXTURfpejtmPeB4-sxgj7OFxMYYus84BdkG9BZgSBmMN9SqZItOv4pqg_NwQA0bv9g9A_e-N6QUFanxuYQsEPX_-IwWBDbNKyN9bXbpEQa0FKNVsTvFahGzOxQngXipi265VADkh8MJLjYerplKIbNeOJJbLd9CbS9fceLvQUNr3ACGgAejSaWmeNUVqbho1lY4882iS8QVx1VzjluTXlAMdSUUDHArHEihz008kCyF0YfvNdGebyEDLvTmF6KkhqMpsWn3zASYBidc9k._ch9BtvRRhcLD838itIQlw",
    "claims": {
        "minTLSCertDuration": "5m",
        "maxTLSCertDuration": "24h",
        "defaultTLSCertDuration": "24h",
        "disableRenewal": false,
        "minHostSSHCertDuration": "5m",
        "maxHostSSHCertDuration": "1680h",
        "minUserSSHCertDuration": "5m",
        "maxUserSSHCertDuration": "24h",
        "enableSSHCA": true
    },
    "options": {
        "x509": {
            "templateFile": "templates/certs/x509/default.tpl"
        },
        "ssh": {
            "templateFile": "templates/certs/ssh/default.tpl"
        }
    }
}
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`type`}</strong>{`: for a JWK provisioner it must be `}<inlineCode parentName="p">{`JWK`}</inlineCode>{`, this field is case insensitive.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`name`}</strong>{`: identifies the provisioner, a good practice is to use an email address or a descriptive string that allows the identification of the owner, but it can be any non-empty string.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`key`}</strong>{`: is the JWK (JSON Web Key) representation of a public key
used to validate a signed token.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`encryptedKey`}</strong><Reference id="star1" marker="*" mdxType="Reference" />{`: is the encrypted private key used to sign a token.
It's a JWE compact string containing the JWK representation of the private key.
This value is not necessary for CA operation, but is provided for the convenience of clients.
Without the `}<inlineCode parentName="p">{`encryptedKey`}</inlineCode>{` attribute, the private key must be provided by the client, using the `}<inlineCode parentName="p">{`--key`}</inlineCode>{` flag.`}</p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`claims`}</strong><Reference id="star2" marker="**" mdxType="Reference" />{`: overwrites the default claims set in the authority.
See `}<a parentName="p" {...{
            "href": "#claims"
          }}>{`claims`}</a>{` for details.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`options`}</strong><Reference id="star2" marker="*" mdxType="Reference" />{`: see `}<a parentName="p" {...{
            "href": "templates#configuring-the-ca-to-use-templates"
          }}>{`template configuration`}</a>{` for more details.`}</p>
      </li>
    </ul>
    <Footnote id="star1" marker="*" mdxType="Footnote">Recommended</Footnote>
    <Footnote id="star2" marker="**" mdxType="Footnote">Optional</Footnote>
    <br /><br />
    <h4>{`Decrypting the private key`}</h4>
    <p>{`We can use `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/crypto/jwe/decrypt"
      }}><inlineCode parentName="a">{`step crypto jwe decrypt`}</inlineCode></a>{` to see the private key encrypted with the password `}<inlineCode parentName="p">{`asdf`}</inlineCode>{`:`}</p>
    <CodeBlock language="shell-session" mdxType="CodeBlock">
      {`$ step ca provisioner list \\
    | jq -r '.[] | select(.name == "you@smallstep.com") | .encryptedKey' \\
	| step crypto jwe decrypt \\
	| jq
Please enter the password to decrypt the content encryption key:
{
  "use": "sig",
  "kty": "EC",
  "kid": "NPM_9Gz_omTqchS6Xx9Yfvs-EuxkYo6VAk4sL7gyyM4",
  "crv": "P-256",
  "alg": "ES256",
  "x": "bBI5AkO9lwvDuWGfOr0F6ttXC-ZRzJo8kKn5wTzRJXI",
  "y": "rcfaqE-EEZgs34Q9SSH3f9Ua5a8dKopXNfEzDD8KRlU",
  "d": "rsjCCM_2FQ-uk7nywBEQHl84oaPo4mTpYDgXAu63igE"
}`}
    </CodeBlock>
    <h4>{`Changing a JWK provisioner password`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Retrieve the current encrypted key.`}</p>
        <p parentName="li">{`Run the following, changing the provisioner name in the `}<inlineCode parentName="p">{`jq`}</inlineCode>{` command to match your configuration:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`OLD_ENCRYPTED_KEY=$(step ca provisioner list \\
   | jq -r '.[] | select(.name == "you@smallstep.com").encryptedKey')
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Update the encrypted key password.`}</p>
        <p parentName="li">{`Run:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`ENCRYPTED_KEY=$(echo $OLD_ENCRYPTED_KEY | \\
  step crypto jwe decrypt | \\
  step crypto jwe encrypt --alg PBES2-HS256+A128KW | \\
  step crypto jose format)
`}</code></pre>
        <p parentName="li">{`You'll be asked for the old and new passwords.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Update the provisioner.`}</p>
        <p parentName="li">{`Run the following, changing the provisioner name in the command to match your configuration:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`step ca provisioner update you@smallstep.com \\
  --private-key=<(echo -n "$ENCRYPTED_KEY")
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Send a `}<inlineCode parentName="p">{`SIGHUP`}</inlineCode>{` to `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to reload the configuration file.`}</p>
        <p parentName="li">{`(Skip this step if you have enabled remote provisioner administration.)`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`killall -i -s SIGHUP step-ca
`}</code></pre>
      </li>
    </ol>
    <h4>{`Rekeying a JWK provisioner`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Update the provisioner.`}</p>
        <p parentName="li">{`Run the following, changing the provisioner name in the command to match your configuration:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`step ca provisioner update you@smallstep.com --create
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Send a `}<inlineCode parentName="p">{`SIGHUP`}</inlineCode>{` to `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to reload the configuration file.`}</p>
        <p parentName="li">{`(Skip this step if you have enabled remote provisioner administration.)`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`killall -i -s SIGHUP step-ca
`}</code></pre>
      </li>
    </ol>
    <h4>{`Removing the encrypted private key from a JWK provisioner`}</h4>
    <p>{`The encrypted private key stored in the JWK provisioner configuration and published to the public `}<inlineCode parentName="p">{`/provisioners`}</inlineCode>{` endpoint is provided for client convenience.
It is not required for `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to operate.
To remove this key:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Update the provisioner.`}</p>
        <p parentName="li">{`Run the following, changing the provisioner name in the command to match your configuration:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`step ca provisioner update you@smallstep.com --private-key ""
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Send a `}<inlineCode parentName="p">{`SIGHUP`}</inlineCode>{` to `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to reload the configuration file.`}</p>
        <p parentName="li">{`(Skip this step if you have enabled remote provisioner administration.)`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`killall -i -s SIGHUP step-ca
`}</code></pre>
      </li>
    </ol>
    <h3>{`OAuth/OIDC Single Sign-on`}</h3>
    <p>{`Sometimes it's useful to issue certificates to people.
So `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` supports single sign-on with identity providers (IdPs) like Google, Okta, Azure Active Directory, Keycloak,
or any other provider that supports OAuth's `}<a parentName="p" {...{
        "href": "https://openid.net/connect/"
      }}>{`OpenID Connect extension`}</a>{`..`}</p>
    <p>{`OpenID Connect is an extension to OAuth 2.0 that adds an identity layer.
Providers that support OIDC can issue identity tokens ("ID tokens") to OAuth clients.
These are JSON Web Tokens (JWTs) containing user identity information (eg. full name, username, email address).
Like certificates, OIDC tokens have a validity period and are cryptographically signed by a trust authority (the OAuth provider).`}</p>
    <p>{`Here's an example OIDC identity token issued by Google:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "alg": "RS256",
  "kid": "cd49b2ab16e1e9a496c8239dac0dadd09d443012",
  "typ": "JWT"
}.{
  "iss": "https://accounts.google.com",
  "azp": "1087160488420-8qt7bavg3qesdhs6it824mhnfgcfe8il.apps.googleusercontent.com",
  "aud": "1087160488420-8qt7bavg3qesdhs6it824mhnfgcfe8il.apps.googleusercontent.com",
  "sub": "115449349109627210866",
  "hd": "smallstep.com",
  "email": "mike@smallstep.com",
  "email_verified": true,
  "at_hash": "lE6o-GdMpurFQ0WrJ9-H7g",
  "nonce": "5f5820880a43c3f50d55ce79af15430b14b4059bdf4efbe717da6af8bfc53122",
  "iat": 1621877714,
  "exp": 1621881314
}.[Signature]
`}</code></pre>
    <p>{`The OIDC provisioner in `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` can be configured to trust and accept an OAuth provider's ID tokens for authentication.
By default, the issued certificate will use the `}<em parentName="p">{`subject`}</em>{` (sub) claim from the identity token as its subject.
The value of the token's `}<em parentName="p">{`email`}</em>{` claim is also included as an email `}<em parentName="p">{`SAN`}</em>{` in the certificate.`}</p>
    <p><img parentName="p" {...{
        "src": "/graphics/oauth-provisioner.png",
        "alt": "example of provisioner working with step ca"
      }}></img></p>
    <p><em parentName="p">{`Fig. 3: Diagram of how step works with individuals using a single sign-on provisioner`}</em></p>
    <p>{`From the user's perspective, when requesting a certificate, `}<inlineCode parentName="p">{`step`}</inlineCode>{` detects the OIDC provisioner and initiates the OAuth login flow automatically:`}</p>
    <CodeBlock language="shell-session" copyText="step ca certificate mike@smallstep.com mike.crt mike.key" mdxType="CodeBlock">
      {`$ step ca certificate mike@smallstep.com mike.crt mike.key
 
✔ Key ID: 650445034027-jsjdrkiskeq9ke99ud2rqkst82ft8uch.apps.googleusercontent.com (Google)
✔ CA: https://ca.internal
✔ Certificate: mike.crt
✔ Private Key: mike.key`}
    </CodeBlock>
    <CodeBlock language="shell-session" copyText="step certificate inspect --short mike.crt" mdxType="CodeBlock">
      {`$ step certificate inspect --short mike.crt
X.509v3 TLS Certificate (ECDSA P-256) [Serial: 2581...6739]
Subject:     115449349109627210866
            mike@smallstep.com
Issuer:      Smallstep Intermediate CA
Provisioner: Google [ID: 6504....com]
Valid from:  2019-06-20T18:21:52Z
        to:  2019-06-21T18:21:52Z`}
    </CodeBlock>
    <h4>{`Configuring your identity provider (IdP)`}</h4>
    <p>{`When creating an OAuth app, there isn't much to configure on the IdP.
Most providers will ask you to specify a Redirect URI, where the ID token will be delivered at the end of the OAuth flow.
Since `}<inlineCode parentName="p">{`step`}</inlineCode>{` starts its own local web server to receive the token, use `}<inlineCode parentName="p">{`http://127.0.0.1`}</inlineCode>{` as the Redirect URI.`}</p>
    <h4>{`Example: Google Identity`}</h4>
    <p>{`One of the most common providers, and the one used in the following example, is `}<a parentName="p" {...{
        "href": "https://developers.google.com/identity/protocols/oauth2/openid-connect"
      }}>{`Google Identity`}</a>{`.`}</p>
    <p>{`Add a Google provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ step ca provisioner add Google --type oidc \\
  --client-id 650445034027-jsjdrkiskeq9ke99ud2rqkst82ft8uch.apps.googleusercontent.com \\
  --client-secret 6Q7lGMua_Oox4nA92QBXYypT \\
  --configuration-endpoint https://accounts.google.com/.well-known/openid-configuration \\
  --domain smallstep.com --domain gmail.com
`}</code></pre>
    <p>{`Example `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{` provisioner configuration for a Google provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`{
  "type": "OIDC",
  "name": "Google",
  "clientID": "1087160488420-8qt7bavg3qesdhs6it824mhnfgcfe8il.apps.googleusercontent.com",
  "clientSecret": "udTrOT3gzrO7W9fDPgZQLfYJ",
  "configurationEndpoint": "https://accounts.google.com/.well-known/openid-configuration",
  "admins": ["you@smallstep.com"],
  "domains": ["smallstep.com"],
  "listenAddress": ":10000",
  "claims": {
    "maxTLSCertDuration": "8h",
    "defaultTLSCertDuration": "2h",
    "disableRenewal": true
  },
  "options": {
      "x509": {
          "templateFile": "templates/certs/x509/default.tpl"
      },
      "ssh": {
          "templateFile": "templates/certs/ssh/default.tpl"
      }
  }
}
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`type`}</strong>{`: indicates the provisioner type and must be OIDC.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`name`}</strong>{`: a string used to identify the provider when the CLI is used.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`clientID`}</strong>{`: the client id provided by the identity provider used to initialize the authentication flow.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`clientSecret`}</strong>{`: the shared secret provided by the identity provider; used to get the id token during the OAuth flow. Some identity providers may use an empty string as a secret. In the context of `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, the client "secret" is not actually a secret and is available via the CA's `}<inlineCode parentName="p">{`/provisioners`}</inlineCode>{` configuration endpoint, because every `}<inlineCode parentName="p">{`step`}</inlineCode>{` client needs to use it locally.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`configurationEndpoint`}</strong>{`: is the HTTP address used by the CA to get the OpenID Connect configuration and public keys used to validate the tokens.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`admins`}</strong><Reference id="star3" marker="*" mdxType="Reference" />{`: These users will be allowed to request certificates with any name (custom SANs). Non-admins can only get certificates bound to their own ID and email address.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`domains`}</strong><Reference id="star3" marker="*" mdxType="Reference" />{`: is the list of domains valid. If provided only the emails with the provided domains will be able to authenticate.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`listenAddress`}</strong><Reference id="star3" marker="*" mdxType="Reference" />{`: is the address (`}<inlineCode parentName="p">{`:port`}</inlineCode>{` or `}<inlineCode parentName="p">{`host:port`}</inlineCode>{`) where the authorization server will redirect the client's web browser at the end of the authorization flow. By default, the `}<inlineCode parentName="p">{`step`}</inlineCode>{` client will bind to 127.0.0.1 on a random port. This parameter is only required if the authorization server demands a specific port for loopback IP redirect URIs.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`claims`}</strong><Reference id="star3" marker="*" mdxType="Reference" />{`: overwrites the default claims set in the authority,
see the `}<a parentName="p" {...{
            "href": "#claims"
          }}>{`claims`}</a>{` section for all the options.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`options`}</strong><Reference id="star2" marker="*" mdxType="Reference" />{`: see `}<a parentName="p" {...{
            "href": "templates#configuring-the-ca-to-use-templates"
          }}>{`template configuration`}</a>{` for more details.`}</p>
      </li>
    </ul>
    <Footnote id="star3" marker="*" mdxType="Footnote">Optional</Footnote>
    <h4>{`Browserless Console Mode`}</h4>
    <p>{`Sometimes it's helpful to use OAuth in an input-constrained environment where a web browser is not available.
The Device Authorization Grant flow is an OAuth 2.0 extension designed for this scenario.
The `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` OIDC provisioner supports the Device Authorization Grant flow.`}</p>
    <p>{`To use the Device Authorization Grant flow for input-constrained devices, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ step ca certificate foo foo.crt foo.key --console
`}</code></pre>
    <p>{`To specify a flow other than the default (for example Google's deprecated Out of Band flow), run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ STEP_CONSOLE_FLOW=oob step ca certificate foo foo.crt foo.key --console
`}</code></pre>
    <h4>{`Notes`}</h4>
    <Alert severity="info" mdxType="Alert">
  <div>
    <b>Why is the OAuth client secret unprotected?</b>
        <p>{`When using the OIDC provisioner, you may notice that your OAuth client secret is visible to anyone via the CA's `}<Code mdxType="Code">{`/provisioners`}</Code>{` API endpoint.
Counterintuitively, this a secure implementation of OAuth that conforms to the OAuth Best Current Practices for Native Apps (`}<a href="https://www.rfc-editor.org/rfc/rfc8252.html">{`RFC8252 / IETF BCP212`}</a>{`).
And it is the same approach that Google's `}<Code mdxType="Code">{`gcloud`}</Code>{` CLI tool uses for Google Cloud Platform authentication: An OAuth client secret is hardcoded into its source code.`}</p>
        <p>{`So, what makes it secure?
The Authorization Code flow for native OAuth apps requires the redirect URI hostname be hardcoded as `}<inlineCode parentName="p">{`127.0.0.1`}</inlineCode>{` (or `}<inlineCode parentName="p">{`localhost`}</inlineCode>{`) in the client configuration.
This constraint obviates the need for a client secret, because the loopback address is inherently resistant to network attacks that the client secret is designed to mitigate in other, non-native app flows.`}</p>
        <p>{`An attacker in posession of the client secret would need local access to your device in order to compromise the flow.
OAuth in general is not very resistant to local attacks, so the threat model for the native app flow with an exposed client secret is the same as with any other OAuth flow:
It assumes that if you have a local attacker on your device, it's unlikely that this kind of attack is going to be your biggest threat.`}</p>
        <p>{`The client secret is superfluous in the Authorization Code flow for native apps.
In fact, BCP212 has recommended that OAuth identity providers offer a special OAuth client type that has no client secret.
In practice, very few OAuth providers have implemented this "secretless" approach, so we don't yet support it.
Functionally, however, it is equivalent to having a public secret.`}</p>
        <p>{`Bottom line, the OAuth flow implemented in `}<inlineCode parentName="p">{`step`}</inlineCode>{` and `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` is widely vetted and considered secure.`}</p>
  </div>
    </Alert>
    <h4>{`Further reading`}</h4>
    <ul>
      <li parentName="ul">{`Tutorial: `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/tutorials/keycloak-oidc-provisioner"
        }}>{`Use Keycloak to issue user certificates with step-ca`}</a></li>
      <li parentName="ul">{`Tutorial: `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/tutorials/user-authentication"
        }}>{`Connect your identity provider and issue X.509 certificates for user authentication to services`}</a></li>
    </ul>
    <h3>{`X5C - X.509 Certificate`}</h3>
    <p>{`With the X5C provisioner, a client can authenticate a certificate request
using an existing X.509 certificate. Configure this provisioner with a root
CA certificate, and any certificate bundle that chains up to that root can
be used in a certificate request.`}</p>
    <ul>
      <li parentName="ul">{`Clients may request an X.509 or SSH certificate`}</li>
      <li parentName="ul">{`Clients must provide an X.509 certificate bundle whose root is trusted by the provisioner`}</li>
      <li parentName="ul">{`Clients sign their request with the certificate private key`}</li>
      <li parentName="ul">{`The validity period of the new certificate must fall within the validity period
of the certificate used to authenticate the request`}</li>
    </ul>
    <p>{`The X5C provisioner uses X5C tokens for authentication. An X5C token is a JWT, signed by the certificate private key, with an `}<inlineCode parentName="p">{`x5c`}</inlineCode>{` header that contains the certificate bundle.`}</p>
    <h4>{`Example`}</h4>
    <p>{`If you would like any certificate signed by `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to become a provisioner
(have the ability to request new certificates with any name),
you can create an X5C provisioner using the root certificate used by
`}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add x5c-smallstep --type X5C --x5c-root $(step path)/certs/root_ca.crt
`}</code></pre>
    <p>{`Or, you can configure the X5C provisioner with an outside root, granting provisioner
capabilities to a completely separate PKI.`}</p>
    <p>{`Below is an example of an X5C provisioner in the `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`...
{
    "type": "X5C",
    "name": "x5c",
    "roots": "LS0tLS1 ... Q0FURS0tLS0tCg==",
    "claims": {
        "maxTLSCertDuration": "8h",
        "defaultTLSCertDuration": "2h",
        "disableRenewal": true
    },
    "options": {
        "x509": {
            "templateFile": "templates/certs/x509/default.tpl"
        },
        "ssh": {
            "templateFile": "templates/certs/ssh/default.tpl"
        }
    }
}
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`type`}</strong>{`: indicates the provisioner type and must be `}<inlineCode parentName="p">{`X5C`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`name`}</strong>{`: a string used to identify the provider when the CLI is used.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`roots`}</strong>{`: a base64 encoded list of root certificate PEM blocks used for validating X5C
tokens.`}</p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`claims`}</strong><Reference id="star4" marker="*" mdxType="Reference" />{`: overwrites the default claims set in the authority,
see the `}<a parentName="p" {...{
            "href": "#claims"
          }}>{`claims`}</a>{` section for all the options.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`options`}</strong><Reference id="star4" marker="*" mdxType="Reference" />{`: see `}<a parentName="p" {...{
            "href": "templates#configuring-the-ca-to-use-templates"
          }}>{`template configuration`}</a>{` for more details.`}</p>
      </li>
    </ul>
    <Footnote id="star4" marker="*" mdxType="Footnote">Optional</Footnote>
    <Alert severity="warning" mdxType="Alert">
  <div> 
    By default, the `X5C` provisioner will issue a certificates for <strong>any</strong> Subject names.
    If you want to limit or modify the subject names this provisioner will issue,
    you can use a <a href="/docs/step-ca/templates">certificate template</a>.
    
    For example, say your users will use the X5C provisioner to exchange an X.509 client certificate for an SSH user certificate.
    The X.509 client certificate has `alice@example.com` as its subject,
    and you want to ensure the SSH certificate has both `alice` and `alice@example.com` as principals.
    The following template will make that scenario possible:
    
    <CodeBlock language="json" mdxType="CodeBlock">
	{`{
        "type": {{ toJson .Type }},
        "keyId": {{ toJson .AuthorizationCrt.Subject.CommonName }},
        "principals": [
            {{ replace "@example.com" "" .AuthorizationCrt.Subject.CommonName | toJson }},
            {{ toJson .AuthorizationCrt.Subject.CommonName }}
        ],
        "extensions": {{ toJson .Extensions }},
        "criticalOptions": {{ toJson .CriticalOptions }}
    }`}
    </CodeBlock>
  </div>
    </Alert>
    <h3>{`Nebula`}</h3>
    <p><strong parentName="p">{`This is an experimental feature.`}</strong></p>
    <p>{`If you have a `}<a parentName="p" {...{
        "href": "https://www.defined.net/nebula/"
      }}>{`Nebula overlay network`}</a>{`, you can create a Nebula provisioner and configure it with your Nebula root CA certificate.
Clients can then use their Nebula client certificate and private key to request an X.509 or SSH host certificate from `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.
The Nebula certificate they use for authentication must be issued by the Nebula root CA configured in the provisioner.`}</p>
    <p>{`The Nebula client certificate is used for authorization, too:
The client is allowed to request an X.509 or SSH host certificate with the `}<inlineCode parentName="p">{`name`}</inlineCode>{` or any of the `}<inlineCode parentName="p">{`ips`}</inlineCode>{` appearing on the Nebula client certificate.`}</p>
    <p>{`To be clear, Nebula certificates can contain a single `}<inlineCode parentName="p">{`name`}</inlineCode>{` and a list of `}<inlineCode parentName="p">{`ips`}</inlineCode>{`.
The `}<inlineCode parentName="p">{`name`}</inlineCode>{` field is often a DNS hostname, but it could be an email, IP, or URI.
And `}<inlineCode parentName="p">{`ips`}</inlineCode>{` contains a list of CIDR blocks.
In `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, the Nebula provisioner will authorize certificate subjects or SANs that include the `}<inlineCode parentName="p">{`name`}</inlineCode>{`, plus IPs in any of the CIDR blocks in `}<inlineCode parentName="p">{`ips`}</inlineCode>{` on the Nebula certificate.`}</p>
    <p>{`To get started, create a Nebula provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add --type Nebula --nebula-root /etc/nebula/ca.crt
`}</code></pre>
    <p>{`Now you can get an X509 certificate with the Nebula provisioner, using a Nebula client certificate. Here's an example using the DNS name `}<inlineCode parentName="p">{`host3.example.com`}</inlineCode>{`, and two IPs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca certificate host3.example.com host3.crt host3.key \\
    --nebula-cert /etc/nebula/client.crt \\
    --nebula-key /etc/nebula/client.key \\
    --san 192.168.100.2 \\ 
    --san 192.168.100.3
`}</code></pre>
    <h3>{`SSHPOP - SSH Certificate`}</h3>
    <p>{`An SSHPOP provisioner allows a client to renew, revoke, or rekey an SSH
certificate using that certificate for authentication with the CA.
The renew and rekey methods can only be used on SSH host certificates.`}</p>
    <p>{`An SSHPOP provisioner is configured with the user and host root ssh certificates
from the `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`. The SSHPOP provisioner can only authenticate SSHPOP tokens
generated using SSH certificates created by `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.`}</p>
    <p>{`An SSHPOP token is a JWT, signed by the certificate private key, with an `}<inlineCode parentName="p">{`sshpop`}</inlineCode>{`
header that contains the SSH certificate.`}</p>
    <p>{`When configured with the `}<inlineCode parentName="p">{`--ssh`}</inlineCode>{` option (`}<inlineCode parentName="p">{`step ca init --ssh`}</inlineCode>{`), the CA
will contain a default SSHPOP provisioner named `}<inlineCode parentName="p">{`sshpop`}</inlineCode>{`.`}</p>
    <h4>{`Example`}</h4>
    <p>{`Add an SSHPOP provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add sshpop --type SSHPOP
`}</code></pre>
    <p>{`An example SSHPOP provisioner in the `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`...
{
    "type": "SSHPOP",
    "name": "sshpop",
    "claims": {
        "enableSSHCA": true
    },
    "options": {
        "ssh": {
            "templateFile": "templates/certs/ssh/default.tpl"
        }
    }
}
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`type`}</strong>{`: indicates the provisioner type and must be `}<inlineCode parentName="p">{`SSHPOP`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`name`}</strong>{`: a string used to identify the provider when the CLI is
used.`}</p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`claims`}</strong><Reference id="star5" marker="*" mdxType="Reference" />{`: overwrites the default `}<inlineCode parentName="p">{`claims`}</inlineCode>{` set in the authority,
see the `}<a parentName="p" {...{
            "href": "#claims"
          }}>{`claims`}</a>{` section for all the options.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`options`}</strong><Reference id="star5" marker="*" mdxType="Reference" />{`: see `}<a parentName="p" {...{
            "href": "templates#configuring-the-ca-to-use-templates"
          }}>{`template configuration`}</a>{` for more details.`}</p>
      </li>
    </ul>
    <Footnote id="star5" marker="*" mdxType="Footnote">Optional</Footnote>
    <h3>{`ACME`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc8555"
      }}>{`ACME Protocol`}</a>{` can authenticate Certificate Signing Requests (CSRs) in a way that enables automation.`}</p>
    <p>{`ACME clients must answer challenges presented by the ACME server to prove to the CA that they control the identifiers listed in the CSR.
ACME supports four different types of challenges: `}<inlineCode parentName="p">{`http-01`}</inlineCode>{`, `}<inlineCode parentName="p">{`dns-01`}</inlineCode>{`, `}<inlineCode parentName="p">{`tls-alpn-01`}</inlineCode>{`, and `}<inlineCode parentName="p">{`device-attest-01`}</inlineCode>{`. These are designed for operability in different environments.
See `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/acme-basics#acme-challenges"
      }}>{`ACME Basics`}</a>{` for a description of each challenge type and their tradeoffs.`}</p>
    <p>{`In a typical setup, an ACME server might issue server certificates via the `}<inlineCode parentName="p">{`http-01`}</inlineCode>{`, `}<inlineCode parentName="p">{`dns-01`}</inlineCode>{`, `}<inlineCode parentName="p">{`tls-alpn-01`}</inlineCode>{` challenge types, and client certificates via the `}<inlineCode parentName="p">{`device-attest-01`}</inlineCode>{` challenge type.`}</p>
    <p>{`The ACME provisioner in `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` supports issuing X.509 certificates using IP, hostname, and device identifiers.`}</p>
    <h4>{`Example`}</h4>
    <p>{`Add an ACME provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add acme --type ACME
`}</code></pre>
    <p>{`An example of an ACME provisioner in the `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`...
{
    "type": "ACME",
    "name": "acme",
    "forceCN": true,
    "claims": {
        "maxTLSCertDuration": "8h",
        "defaultTLSCertDuration": "2h"
    },
    "options": {
        "x509": {
            "templateFile": "templates/certs/x509/default.tpl"
        }
    }
}
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`type`}</strong>{`: indicates the provisioner type and must be `}<inlineCode parentName="p">{`ACME`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`name`}</strong>{`: a string used to identify the provider when the CLI is
used.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`forceCN`}</strong><Reference id="star6" marker="*" mdxType="Reference" />{`: force one of the SANs to become the Common Name, if a common name is not provided.`}</p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`claims`}</strong><Reference id="star6" marker="*" mdxType="Reference" />{`: overwrites the default claims set in the authority,
see the `}<a parentName="p" {...{
            "href": "#claims"
          }}>{`claims`}</a>{` section for all the options.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`options`}</strong><Reference id="star6" marker="*" mdxType="Reference" />{`: see `}<a parentName="p" {...{
            "href": "templates#configuring-the-ca-to-use-templates"
          }}>{`template configuration`}</a>{` for more details.`}</p>
      </li>
    </ul>
    <Footnote id="star6" marker="*" mdxType="Footnote">Optional</Footnote>
    <br /><br />
    <p>{`See `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/acme-basics"
      }}>{`ACME Basics`}</a>{`
for more guidance on configuring and using the ACME protocol with `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.`}</p>
    <h4>{`ACME for Device Attestation`}</h4>
    <Alert severity="warning" mdxType="Alert">
  <div> 
    This challenge type must be explicitly enabled in an ACME provisioner.
  </div>
    </Alert>
    <p>{`The `}<inlineCode parentName="p">{`device-attest-01`}</inlineCode>{` challenge supports device attestations from iOS, iPadOS, tvOS, and YubiKeys. An attestation certificate is used to satisfy the ACME challenge. The CSR may include a device ID in a `}<inlineCode parentName="p">{`permanentIdentifier`}</inlineCode>{` SAN (`}<a parentName="p" {...{
        "href": "https://www.rfc-editor.org/rfc/rfc4043.html"
      }}>{`RFC 4043`}</a>{`), depending on the application. `}</p>
    <h5>{`Managed Device Attestation for Apple Devices`}</h5>
    <p>{`To add a `}<inlineCode parentName="p">{`device-attest-01`}</inlineCode>{` provisioner for use with `}<a parentName="p" {...{
        "href": "https://support.apple.com/guide/deployment/managed-device-attestation-dep28afbde6a/web"
      }}>{`Managed Device Attestation`}</a>{` on Apple devices, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`step ca provisioner add acme-da \\
   --type ACME \\
   --challenge device-attest-01 \\
   --attestation-format apple
`}</code></pre>
    <Alert severity="warning" mdxType="Alert">
  <div>
    Apple's device attestation CA will be trusted by the provisioner.
    For a production environment, you must add policies to restrict this provisioner.
    Without policy restrictions, the provisioner may issue certificates **for any Apple device**.
  </div>
    </Alert>
    <p>{`In your Apple MDM profile, you will need:`}</p>
    <ul>
      <li parentName="ul">{`A `}<a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/devicemanagement/certificateroot"
        }}><inlineCode parentName="a">{`CertificateRoot`}</inlineCode></a>{` payload, containing your root CA certificate PEM block, so that it's trusted by the device. `}</li>
      <li parentName="ul">{`An `}<a parentName="li" {...{
          "href": "https://developer.apple.com/documentation/devicemanagement/acmecertificate"
        }}><inlineCode parentName="a">{`ACMECertificate`}</inlineCode></a>{` payload. For this one, set the `}<inlineCode parentName="li">{`ClientIdentifier`}</inlineCode>{` to the UDID or serial number of the device. `}</li>
    </ul>
    <h5>{`Device Attestation for YubiKeys`}</h5>
    <p>{`To add a `}<inlineCode parentName="p">{`device-attest-01`}</inlineCode>{` provisioner for YubiKey devices, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`step ca provisioner add acme-da \\
      --type ACME \\
      --challenge device-attest-01 \\
      --attestation-format step
`}</code></pre>
    <Alert severity="warning" mdxType="Alert">
  <div>
    Yubico's attestation CA will be trusted by the new provisioner.
    For a production environment, you must add policies to restrict this provisioner.
    Without policy restrictions, the provisioner may issue certificates **for any YubiKey**.
  </div>
    </Alert>
    <p>{`To get a client certificate for a hardware-bound private key on your YubiKey:`}</p>
    <ol {...{
      "start": 0
    }}>
      <li parentName="ol">
        <p parentName="li">{`Create a `}<inlineCode parentName="p">{`device-attest-01`}</inlineCode>{` provisioner as described above.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Install `}<a parentName="p" {...{
            "href": "https://github.com/smallstep/step-kms-plugin/"
          }}><inlineCode parentName="a">{`step-kms-plugin`}</inlineCode></a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Insert the YubiKey.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Generate a private key in one of the YubiKey's slots, if you haven't already.
In this example, we'll use slot `}<inlineCode parentName="p">{`9a`}</inlineCode>{`.
Run:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`step kms create 'yubikey:slot-id=9a' \\
    --kms 'yubikey:?pin-value=123456'
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Get a certificate for the YubiKey from `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.
Substitute the serial number of your YubiKey here:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`{
  YUBIKEY_SERIAL_NO=123EXAMPLE90
  step ca certificate 
   --attestation-uri 'yubikey:slot-id=9a' \\
   --kms 'yubikey:?pin-value=123456' \\
   --provisioner acme-da $YUBIKEY_SERIAL_NO yubikey.crt
}
`}</code></pre>
      </li>
    </ol>
    <h3>{`SCEP`}</h3>
    <p>{`The SCEP provisioner can sign and renew certificates using the SCEP protocol (`}<a parentName="p" {...{
        "href": "https://datatracker.ietf.org/doc/html/rfc8894"
      }}>{`RFC8894`}</a>{`).
SCEP is very popular for use in network equipment and mobile device management (MDM).
It runs over HTTP using POSTed binary data or base64-encoded GET parameters,
using CMS (PKCS#7) and CSR (PKCS#10) data formats.
A shared secret authenticates clients to the CA.`}</p>
    <h4>{`Requirements`}</h4>
    <p>{`Your CA must use an RSA intermediate CA, even if your client supports ECDSA.
The RSA intermediate is used to decrypt the contents of the SCEP `}<inlineCode parentName="p">{`pkcsPKIEnvelope`}</inlineCode>{` containing the certificate request.
This operation cannot be performed using an ECDSA key.`}</p>
    <p>{`Because `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/init"
      }}><inlineCode parentName="a">{`step ca init`}</inlineCode></a>{` creates an ECDSA chain by default, you will need to `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/tutorials/rsa-chain"
      }}>{`convert your CA to use an RSA CA chain`}</a>{` before using the SCEP provisioner.`}</p>
    <Alert severity="info" mdxType="Alert">
  <div>
	  <p>
      <strong>Note:</strong> Some SCEP clients may fail if the intermediate CA certificate does not contain the right key usage extensions or does contain otherwise unexpected content. 
      Consult the documentation of your SCEP client for specific configuration required or ask us on <Link href="https://u.step.sm/discord" mdxType="Link">Discord</Link> or in <Link href="https://github.com/smallstep/certificates/discussions" mdxType="Link">GitHub Discussions</Link> if you hit a blocker.
    </p>
  </div>
    </Alert>
    <h4>{`Configure the Provisioner`}</h4>
    <p>{`In this example, we will add a SCEP provisioner using challenge secret `}<inlineCode parentName="p">{`secret1234`}</inlineCode>{` and `}<inlineCode parentName="p">{`AES-256-CBC`}</inlineCode>{` as the `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/pkcs7/blob/33d05740a3526e382af6395d3513e73d4e66d1cb/encrypt.go#L63"
      }}>{`encryption algorithm`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add my_scep_provisioner \\
  --type SCEP --challenge "secret1234" \\
  --encryption-algorithm-identifier 2
`}</code></pre>
    <p>{`The shared `}<inlineCode parentName="p">{`challenge`}</inlineCode>{` should be a value that you will distribute to your SCEP clients.`}</p>
    <p>{`Here's an example of a SCEP provisioner in `}<inlineCode parentName="p">{`$(step path)/config/ca.json`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "type": "SCEP",
    "name": "scepca",
    "forceCN": true,
    "challenge": "secret1234",
    "minimumPublicKeyLength": 2048,
    "includeRoot": true,
    "encryptionAlgorithmIdentifier": 2,
}
`}</code></pre>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`forceCN`}</inlineCode>{` parameter is optional.
It behaves the same as `}<inlineCode parentName="li">{`forceCN`}</inlineCode>{` in the ACME provisioner, and it defaults to false.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`challenge`}</inlineCode>{` is the secret shared between the provisioner and SCEP clients. By default no secret is used.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`minimumPublicKeyLength`}</inlineCode>{` parameter can be used to set the minimum length of public keys submitted by a client. Defaults to 2048.`}</li>
      <li parentName="ul">{`When `}<inlineCode parentName="li">{`includeRoot`}</inlineCode>{` is set to true, the root CA certificate will be returned in responses to `}<inlineCode parentName="li">{`GetCACert`}</inlineCode>{` requests in addition to the intermediate CA certificate. This option was added to support a specific use case for the macOS SCEP client (see `}<a parentName="li" {...{
          "href": "https://github.com/smallstep/certificates/issues/746"
        }}>{`certificates#746`}</a>{` for more details). Defaults to false.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`encryptionAlgorithmIdentifier`}</inlineCode>{` parameter can be used to change the `}<a parentName="li" {...{
          "href": "https://github.com/smallstep/pkcs7/blob/33d05740a3526e382af6395d3513e73d4e66d1cb/encrypt.go#L63"
        }}>{`encryption algorithm`}</a>{` used for encrypting the request content. Defaults to 0: `}<inlineCode parentName="li">{`DES-CBC`}</inlineCode>{` for legacy compatibility.`}</li>
    </ul>
    <h4>{`Enable the HTTP Server`}</h4>
    <p>{`By default SCEP will only be served via HTTPS.
Most SCEP clients use HTTP, so you will most likely need your CA to listen using HTTP too, which it does not do by default.
Enable this by filling in the `}<inlineCode parentName="p">{`"insecureAddress"`}</inlineCode>{` property to your top-level CA configuration:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`        ...
        "insecureAddress": ":8080",
        ...
`}</code></pre>
    <p>{`Finally, restart `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.
Your SCEP provisioner is now available at the endpoint `}<inlineCode parentName="p">{`http://ca.example.com:8080/scep/scepca`}</inlineCode>{`.`}</p>
    <Alert severity="info" mdxType="Alert">
  <div>
	<p><strong>Note:</strong> Some SCEP clients expect a specific path segment at the end of the SCEP URL (Cisco Catalyst switches, for example). For compatibility with these clients, the SCEP provisioner is also made available at any path segment beneath the configured provisioner endpoint.</p>
  </div>
    </Alert>
    <h3>{`K8sSA - Kubernetes Service Account`}</h3>
    <p>{`A K8sSA provisioner allows a client to request a certificate from the server
using a Kubernetes Service Account Token.`}</p>
    <p>{`As of the time when this provisioner was coded, the Kubernetes Service Account
API for retrieving the token from a running instance was still in beta. Therefore,
our K8sSA provisioner must be configured with the public key that will be used
to validate K8sSA tokens.`}</p>
    <p>{`K8sSA tokens are very minimal. There is no place for SANs, or other details that
a user may want validated in a CSR. It is essentially a bearer token. Therefore,
at this time a K8sSA token can be used to sign a CSR with any SANs. Said
differently, the `}<strong parentName="p">{`K8sSA provisioner does little to no validation on the CSR
before signing it`}</strong>{`. You should only configure and use this provisioner if you
know what you are doing. If a malicious user obtains the private key they will
be able to create certificates with any SANs and Subject.`}</p>
    <h4>{`Example`}</h4>
    <p>{`Add a K8sSA provsioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add my-kube-provisioner --type K8sSA --pem-keys key.pub
`}</code></pre>
    <p>{`An example of a K8sSA provisioner in the `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`...
{
    "type": "K8sSA",
    "name": "my-kube-provisioner",
    "publicKeys": "LS0tLS1...LS0tCg==",
    "claims": {
        "maxTLSCertDuration": "8h",
        "defaultTLSCertDuration": "2h"
    },
    "options": {
        "x509": {
            "templateFile": "templates/certs/x509/default.tpl"
        }
    }
}
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`type`}</strong>{`: indicates the provisioner type and must be `}<inlineCode parentName="p">{`K8sSA`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`name`}</strong>{`: a string used to identify the provider when the CLI is
used.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`publicKeys`}</strong>{`: a base64 encoded list of public keys used to validate
K8sSA tokens.`}</p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`claims`}</strong><Reference id="star7" marker="*" mdxType="Reference" />{`: overwrites the default claims set in the authority,
see the `}<a parentName="p" {...{
            "href": "#claims"
          }}>{`claims`}</a>{` section for all the options.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`options`}</strong><Reference id="star7" marker="*" mdxType="Reference" />{`: see `}<a parentName="p" {...{
            "href": "templates#configuring-the-ca-to-use-templates"
          }}>{`template configuration`}</a>{` for more details.`}</p>
      </li>
    </ul>
    <Footnote id="star7" marker="*" mdxType="Footnote">Optional</Footnote>
    <h2>{`Cloud Provisioners`}</h2>
    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` can be configured to use instance identity documents (IIDs) to authorize certificate signing requests from cloud VMs running on AWS, GCP, or Azure.
IIDs are signed JSON tokens, created when the instance is launched, and made available via the instance metadata API.`}</p>
    <p>{`Here's the contents of an example IID from AWS:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "devpayProductCodes" : null,
    "marketplaceProductCodes" : [ "1abc2defghijklm3nopqrs4tu" ], 
    "availabilityZone" : "us-west-2b",
    "privateIp" : "10.158.112.84",
    "version" : "2017-09-30",
    "instanceId" : "i-1234567890abcdef0",
    "billingProducts" : null,
    "instanceType" : "t2.micro",
    "accountId" : "123456789012",
    "imageId" : "ami-5fb8c835",
    "pendingTime" : "2016-11-19T16:32:11Z",
    "architecture" : "x86_64",
    "kernelId" : null,
    "ramdiskId" : null,
    "region" : "us-west-2"
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/graphics/iid-provisioner.png",
        "alt": "example of provisioner working with step ca"
      }}></img></p>
    <p><em parentName="p">{`Fig. 2: Diagram of how step works with a cloud service as the provisioner`}</em></p>
    <p>{`Once you’ve configured `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to accept IIDs for authentication,
`}<inlineCode parentName="p">{`step`}</inlineCode>{` will detect the provisioner type,
obtain an IID token from your cloud provider's metadata API,
and use it to obtain a certificate from `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.`}</p>
    <p>{`From the CA's perspective, an IID is a single-use token.
A host can only get one certificate, ever, per IID.
A host can then renew its certificate using `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/renew"
      }}><inlineCode parentName="a">{`step ca renew`}</inlineCode></a>{`.
If the certificate ever expires, the host will need to use a different provisioner to issue a new one.`}</p>
    <h3>{`Security Risks and Limitations`}</h3>
    <p>{`While IIDs simplify the integration of `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, the approach comes with risks.`}</p>
    <p>{`Unfortunately, an IID usually doesn't contain enough information to authenticate a host certificate.
For example, while an IID may contain the private or public IP for the host,
it will not contain all of the DNS names and IP addresses that you may want on the certificate.`}</p>
    <p>{`Because of this, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`'s cloud provisioners use the `}<em parentName="p">{`Trust on First Use (TOFU)`}</em>{` security model,
allowing any instance to get a certificate with any names (SANs) on it.`}</p>
    <p>{`This allows for a "cryptographic perimeter":
If a host presents a certificate that was signed by your CA using a cloud provisioner,
and the CA is configured to verify the instance's account, project, or tenant ID in the IID,
you can have some confidence that the request came from your infrastructure (not someone else's),
but `}<em parentName="p">{`you cannot assume that the names on the certificate are authentic`}</em>{`.`}</p>
    <p>{`Because of this, every host in your infrastructure must be trusted.`}</p>
    <h4>{`Mitigating the risk of IIDs`}</h4>
    <p>{`Here are some things you can do to mitigate risk when using IIDs:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Configure the provisioner with `}<inlineCode parentName="strong">{`instanceAge`}</inlineCode>{`.`}</strong>{`
The IID will effectively "expire" if it's not used within `}<inlineCode parentName="li">{`instanceAge`}</inlineCode>{` after the instance is launched.`}</li>
      <li parentName="ul"><strong parentName="li">{`Use a trusted launch configuration / User Data script to obtain a certificate.`}</strong>{`
Coupled with `}<inlineCode parentName="li">{`instanceAge`}</inlineCode>{`, this will give you more assuance that the names on your certificates can be trusted.`}</li>
      <li parentName="ul"><strong parentName="li">{`Restrict provisioning by your cloud provider account or project IDs.`}</strong>{`
Configure the provisioner's `}<inlineCode parentName="li">{`accounts`}</inlineCode>{` (AWS) or `}<inlineCode parentName="li">{`projectIDs`}</inlineCode>{` (GCP) setting.`}</li>
      <li parentName="ul"><strong parentName="li">{`Disable Custom SANs, if possible.`}</strong>{`
When using the `}<inlineCode parentName="li">{`disableCustomSANs`}</inlineCode>{` setting, the CA will only issue certificates with authentic name(s) extracted from the signed instance identity document.
Unfortunately, the names on the IID may not be the names that you use to refer to your servers and services.`}</li>
      <li parentName="ul"><strong parentName="li">{`Instances that don't obtain a certificate are a risk.`}</strong>{`
Anyone with access to the instance will be able to obtain a certificate binding any names,
so long as the instance is younger than `}<inlineCode parentName="li">{`instanceAge`}</inlineCode>{`.
Consider requesting certificates even for instances that will never use them,
so that the IID cannot later be used by an attacker.`}</li>
    </ul>
    <p>{`There are a lot of details to get right to make this model secure, many of which are environment-dependent and beyond the scope of this document.`}</p>
    <h3>{`AWS`}</h3>
    <p>{`The AWS provisioner allows granting a certificate to an Amazon EC2 instance
using the `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/instance-identity-documents.html"
      }}>{`Instance Identity Documents`}</a></p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/cli"
      }}><inlineCode parentName="a">{`step`}</inlineCode></a>{` CLI will generate a custom JWT
token containing the instance identity document and its signature and the CA
will grant a certificate after validating it.`}</p>
    <h4>{`Example`}</h4>
    <p>{`Find your AWS `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/general/latest/gr/acct-identifiers.html"
      }}>{`account ID`}</a>{` to restrict access to our VMs:`}</p>
    <p><img parentName="p" {...{
        "src": "/graphics/aws-account-id.png",
        "alt": "AWS Account ID"
      }}></img></p>
    <p>{`On the host running `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` add an AWS provisioner to your configuration by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add "AWS IID Provisioner" --type AWS --aws-account 123456789042
`}</code></pre>
    <p>{`In the `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`, an AWS provisioner looks like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "type": "AWS",
    "name": "Amazon Web Services",
    "accounts": ["123456789042"],
    "disableCustomSANs": false,
    "disableTrustOnFirstUse": false,
    "instanceAge": "1h",
    "claims": {
        "maxTLSCertDuration": "2160h",
        "defaultTLSCertDuration": "2160h"
    },
    "options": {
        "x509": {
            "templateFile": "templates/certs/x509/default.tpl"
        }
    }
}
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`type`}</strong>{`: indicates the provisioner type and must be `}<inlineCode parentName="p">{`AWS`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`name`}</strong>{`: a string used to identify the provider when the CLI is
used.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`accounts`}</strong><Reference id="star8" marker="*" mdxType="Reference" />{`: the list of AWS account numbers that are allowed to use
this provisioner. If none is specified, all AWS accounts will be valid.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`disableCustomSANs`}</strong><Reference id="star8" marker="*" mdxType="Reference" />{`: by default custom SANs are valid, but if this
option is set to true only the SANs available in the instance identity
document will be valid, these are the private IP and the DNS
`}<inlineCode parentName="p">{`ip-<private-ip>.<region>.compute.internal`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`disableTrustOnFirstUse`}</strong><Reference id="star8" marker="*" mdxType="Reference" />{`: by default only one certificate will be
granted per instance, but if the option is set to true this limit is not set
and different tokens can be used to get different certificates.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`instanceAge`}</strong><Reference id="star8" marker="*" mdxType="Reference" />{`: The maximum age of an instance that should be allowed to obtain a certificate.
Limits certificate issuance to new instances to mitigate the risk of credential-misuse from instances that don't need a certificate.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`claims`}</strong><Reference id="star8" marker="*" mdxType="Reference" />{`: overwrites the default claims set in the authority,
see the `}<a parentName="p" {...{
            "href": "#claims"
          }}>{`claims`}</a>{` section for all the options.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`options`}</strong><Reference id="star8" marker="*" mdxType="Reference" />{`: see `}<a parentName="p" {...{
            "href": "templates#configuring-the-ca-to-use-templates"
          }}>{`template configuration`}</a>{` for more details.`}</p>
      </li>
    </ul>
    <Footnote id="star8" marker="*" mdxType="Footnote">Optional</Footnote>
    <h3>{`GCP`}</h3>
    <p>{`The GCP provisioner grants certificates to Google Compute Engine instance using
its `}<a parentName="p" {...{
        "href": "https://cloud.google.com/compute/docs/instances/verifying-instance-identity"
      }}>{`identity`}</a>{`
token. The CA will validate the JWT and grant a certificate.`}</p>
    <h4>{`Example`}</h4>
    <p>{`On the host running `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, add an GCP provisioner to your configuration by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add Google --type GCP \\
    --gcp-service-account 1234567890-compute@developer.gserviceaccount.com \\
    --gcp-service-account 9876543210-compute@developer.gserviceaccount.com \\
    --gcp-project identity --gcp-project accounting
`}</code></pre>
    <p>{`In the `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`, a GCP provisioner looks like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "type": "GCP",
    "name": "Google Cloud",
    "serviceAccounts": ["1234567890"],
    "projectIDs": ["project-id"],
    "disableCustomSANs": false,
    "disableTrustOnFirstUse": false,
    "instanceAge": "1h",
    "claims": {
        "maxTLSCertDuration": "2160h",
        "defaultTLSCertDuration": "2160h"
    },
    "options": {
        "x509": {
            "templateFile": "templates/certs/x509/default.tpl"
        }
    }
}
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`type`}</strong>{`: indicates the provisioner type and must be `}<inlineCode parentName="p">{`GCP`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`name`}</strong>{`: a string used to identify the provider when the CLI is used.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`serviceAccounts`}</inlineCode><Reference id="star9" marker="*" mdxType="Reference" />{`: the list of service account numbers that are
allowed to use this provisioner. If none is specified, all service accounts
will be valid.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`projectIDs`}</strong><Reference id="star9" marker="*" mdxType="Reference" />{`: the list of project identifiers that are allowed to
use this provisioner. If non is specified all project will be valid.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`disableCustomSANs`}</strong><Reference id="star9" marker="*" mdxType="Reference" />{`: by default custom SANs are valid, but if this
option is set to true only the SANs available in the instance identity
document will be valid, these are the DNS
`}<inlineCode parentName="p">{`<instance-name>.c.<project-id>.internal`}</inlineCode>{` and
`}<inlineCode parentName="p">{`<instance-name>.<zone>.c.<project-id>.internal`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`disableTrustOnFirstUse`}</strong><Reference id="star9" marker="*" mdxType="Reference" />{`: by default only one certificate will be
granted per instance, but if the option is set to true this limit is not set
and different tokens can be used to get different certificates.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`instanceAge`}</strong><Reference id="star9" marker="*" mdxType="Reference" />{`: The maximum age of an instance that should be allowed to obtain a certificate.
Limits certificate issuance to new instances to mitigate the risk of credential-misuse from instances that don't need a certificate.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`claims`}</strong><Reference id="star9" marker="*" mdxType="Reference" />{`: overwrites the default claims set in the authority,
see the `}<a parentName="p" {...{
            "href": "#claims"
          }}>{`claims`}</a>{` section for all the options.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`options`}</strong><Reference id="star7" marker="*" mdxType="Reference" />{`: see `}<a parentName="p" {...{
            "href": "templates#configuring-the-ca-to-use-templates"
          }}>{`template configuration`}</a>{` for more details.`}</p>
      </li>
    </ul>
    <Footnote id="star9" marker="*" mdxType="Footnote">Optional</Footnote>
    <h3>{`Azure`}</h3>
    <p>{`The Azure provisioner grants certificates to Microsoft Azure instances using
the `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/azure/active-directory/managed-identities-azure-resources/how-to-use-vm-token"
      }}>{`managed identities tokens`}</a>{`.
The CA will validate the JWT and grant a certificate.`}</p>
    <h4>{`Example`}</h4>
    <p>{`On the host running `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, add an Azure provisioner to your configuration by running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca provisioner add Azure --type Azure \\
    --azure-tenant bc9043e2-b645-4c1c-a87a-78f8644bfe57 \\
    --azure-resource-group identity --azure-resource-group accounting
`}</code></pre>
    <p>{`In the `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`, an Azure provisioner looks like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "type": "Azure",
    "name": "Microsoft Azure",
    "tenantId": "b17c217c-84db-43f0-babd-e06a71083cda",
    "resourceGroups": ["backend", "accounting"],
    "audience": "https://management.azure.com/",
    "disableCustomSANs": false,
    "disableTrustOnFirstUse": false,
    "claims": {
        "maxTLSCertDuration": "2160h",
        "defaultTLSCertDuration": "2160h"
    },
    "options": {
        "x509": {
            "templateFile": "templates/certs/x509/default.tpl"
        }
    }
}
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`type`}</strong>{`: indicates the provisioner type and must be `}<inlineCode parentName="p">{`Azure`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`name`}</strong>{`: a string used to identify the provider when the CLI is used.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`tenantId`}</strong>{`: the Azure account tenant id for this provisioner. This
id is the Directory ID available in the Azure Active Directory properties.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`audience`}</strong><Reference id="star10" marker="*" mdxType="Reference" />{`: defaults to `}<inlineCode parentName="p">{`https://management.azure.com/`}</inlineCode>{` but it can
be changed if necessary.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`resourceGroups`}</strong><Reference id="star10" marker="*" mdxType="Reference" />{`: the list of resource group names that are allowed
to use this provisioner. If none is specified, all resource groups will be
valid.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`disableCustomSANs`}</strong><Reference id="star10" marker="*" mdxType="Reference" />{`: by default custom SANs are valid, but if this
option is set to true only the SANs available in the token will be valid, in
Azure only the virtual machine name is available.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`disableTrustOnFirstUse`}</strong><Reference id="star10" marker="*" mdxType="Reference" />{`: by default only one certificate will be
granted per instance, but if the option is set to true this limit is not set
and different tokens can be used to get different certificates.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`claims`}</strong><Reference id="star10" marker="*" mdxType="Reference" />{`: overwrites the default claims set in the authority,
see the `}<a parentName="p" {...{
            "href": "#claims"
          }}>{`claims`}</a>{` section for all the options.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`options`}</strong><Reference id="star10" marker="*" mdxType="Reference" />{`: see `}<a parentName="p" {...{
            "href": "templates#configuring-the-ca-to-use-templates"
          }}>{`template configuration`}</a>{` for more details.`}</p>
      </li>
    </ul>
    <Footnote id="star10" marker="*" mdxType="Footnote">Optional</Footnote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      